import { useForm } from 'react-hook-form'
import React, { useRef } from 'react'
import * as emailjs from 'emailjs-com'
import Swal from 'sweetalert2'

function ContactForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      from_name: '',
      reply_to: '',
      message: '',
    },
  })
  const form = useRef()
  function onSubmit(data, e) {
    e.preventDefault()
    try {
      SendEmail()
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: "Ooops, Quelque chose s'est mal passé",
        text: error.text,
      })
    }
  }
  function SendEmail() {
    emailjs
      .sendForm(
        'service_usjrtib',
        'template_ois75nk',
        form.current,
        'user_cdAZAnl4qUQkoXKGXmSSr'
      )
      .then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'votre message a bien été envoyé, merci!',
        })
      })
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        from_name: '',
        reply_to: '',
        message: '',
      })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form
      id="contact-form"
      ref={form}
      noValidate={true}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="lg:w-1/2 md:w-2/3 mx-auto">
        <div className="flex flex-wrap -m-2">
          <div className="p-2 w-1/2">
            <div className="relative">
              <label htmlFor="from_name" className="leading-7 text-sm">
                Nom
              </label>
              <input
                type="text"
                placeholder={'Votre Nom'}
                name="from_name"
                {...register('from_name', {
                  required: {
                    value: true,
                    message: "Entrez votre nom s'il vous plait!",
                  },
                })}
                className="w-full bg-vbo_blue_regular bg-opacity-50 rounded border border-vbo_blue_dark focus:border-vbo_blue_regular focus:bg-white focus:ring-2 focus:ring-vbo_blue_regular text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            {errors.from_name && (
              <div
                className={
                  'bg-red-100 border border-red-300 text-red-400 px-4 py-2 mt-1 rounded relative'
                }
                role="alert"
              >
                <strong className="font-bold">
                  {errors.from_name.message}
                </strong>
              </div>
            )}
          </div>
          <div className="p-2 w-1/2">
            <div className="relative">
              <label htmlFor="reply_to" className="leading-7 text-sm">
                Email
              </label>
              <input
                type="email"
                placeholder={'Votre Mail'}
                name="reply_to"
                {...register('reply_to', {
                  required: {
                    value: true,
                    message: "Entrez votre Email s'il vous plait!",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message:
                      'Entrez un mail valide, ce sera plus pratique pour vous répondre :-)',
                  },
                })}
                className="w-full bg-vbo_blue_regular bg-opacity-50 rounded border border-vbo_blue_dark focus:border-vbo_blue_regular focus:bg-white focus:ring-2 focus:ring-vbo_blue_regular text-base outline-none text-vbo_blue_dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />

              {errors.reply_to && (
                <div
                  className={
                    'bg-red-100 border border-red-300 text-red-400 px-4 py-2 mt-1 rounded relative'
                  }
                  role="alert"
                >
                  <strong className="font-bold">
                    {errors.reply_to.message}
                  </strong>
                </div>
              )}
            </div>
          </div>
          <div className="p-2 w-full">
            <div className="relative">
              <label htmlFor="register" className="leading-7 text-sm">
                Message
              </label>
              <textarea
                name="message"
                placeholder={'Votre Message'}
                {...register('message', {
                  required: {
                    value: true,
                    message: "N'oubliez pas de me mettre un petit mot :-)",
                  },
                })}
                className="w-full bg-vbo_blue_regular bg-opacity-50 rounded border border-vbo_blue_dark focus:border-vbo_blue_regular focus:bg-white focus:ring-2 focus:ring-vbo_blue_regular h-32 text-base outline-none text-vbo_blue_dark py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              />

              {errors.message && (
                <div
                  className={
                    'bg-red-100 border border-red-300 text-red-400 px-4 py-2 mt-1 rounded relative'
                  }
                  role="alert"
                >
                  <strong className="font-bold">
                    {errors.message.message}
                  </strong>
                </div>
              )}
            </div>
          </div>
          <div className="p-2 w-full">
            <button
              type="submit"
              className="flex mx-auto text-vbo_blue_dark bg-vbo_blue_regular border-vbo_blue_dark py-2 px-8 focus:outline-none hover:text-yellow-600 rounded text-lg"
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
