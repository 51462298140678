import React from 'react'
import { Icon } from '@iconify/react'
import ProjectsSlider from './Slider'

export default function Projects() {
  return (
    <section id={'projects'} className={'pt-10 md:py-4'}>
      <div className={'container mx-auto'}>
        <div className="flex flex-row items-center  text-center  justify-center bg-yellow-600  z-10 mt-8 md:-mt-4 pt-0 sticky md:top-16 md:py-4 ">
          <h1
            className={
              'font-bioRhyme flex items-center text-center px-4 text-vbo_blue_regular text-xl lg:text-4xl  leading-normal uppercase tracking-loose'
            }
          >
            <Icon
              icon="grommet-icons:projects"
              className={'w-10 h-10 hidden md:inline-block '}
            />
            Quelques Réalisations
          </h1>
        </div>
        <div className="container bg-vbo_blue_dark mx-auto my-12 md:my-24">
          <ProjectsSlider />
        </div>
      </div>
    </section>
  )
}
