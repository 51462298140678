import React from 'react'
import $ from 'jquery'
import '../styles/error.scss'
import { Icon } from '@iconify/react'

export default function NotFound() {
  document.addEventListener('DOMContentLoaded', function () {
    SayOoops()
    QuatreCentQuatre()
  })
  const SayOoops = () => {
    $('#oops').show(2000)
  }
  const QuatreCentQuatre = () => {
    document.getElementById('visual').style.transform = 'translate( 225%, 70%)'
    document.getElementById('visual').style.transformOrigin = 'bottom left'
  }

  return (
    <div className={'content text-vbo_blue_dark opacity-90 overflow-hidden'}>
      <a className="a-error-404 border-vbo_blue_dark border-2" href="/">
        <Icon
          icon="akar-icons:arrow-back-thick"
          className={'inline-block m-1 mb-2'}
        />
        Accueil
      </a>
      <div className="background-wrapper ">
        <h1 id="visual" className={''}>
          404
        </h1>
        <h2 id="oops" className={''}>
          Oops!
        </h2>
      </div>
      <p className="p-error-404">
        La page demandée <br />
        n'existe
        <br /> pas
      </p>
    </div>
  )
}
