import { Icon } from '@iconify/react'
import './../styles/banner.scss'

function Banner() {
  return (
    <div
      id={'banner'}
      className={
        'grid grid-rows-1 relative space-y-12 pt-24 mb-6 xs:space-y-24 xs:pt-18 md:mb-12 lg:pt-32 lg:mb-14'
      }
    >
      <div className={'space-y-12 xs:space-y-20 z-10'}>
        <h1
          className={
            'text-center text-xl font-normal leading-normal md:text-3xl uppercase font-bioRhyme filter drop-shadow-lg'
          }
        >
          Solutions Digitales & Marketing
        </h1>
        <p
          className={
            'text-center text-base font-semibold capitalize  tracking-tighter '
          }
        >
          <Icon
            icon="ci:line-m"
            color="#626060"
            className={'mx-auto'}
            height="24"
          />
          Avec une forte appétence pour la Tech
          <Icon
            icon="ci:line-m"
            color="#626060"
            className={'mx-auto'}
            height="24"
          />
        </p>
        <h2
          className={
            'text-center text-xl md:text-2xl text-yellow-600 text-opacity-70 font-pacifico  filter drop-shadow-lg'
          }
        >
          SymfonyDevelopment, NoCodeApps, MarketingStrategy
        </h2>
      </div>
      <a
        href="#about"
        className={
          'mx-auto flex items-center rounded-full border-vbo_blue_regular border-8  z-10  transform transition duration-700 ease-in-out hover:bg-yellow-400'
        }
      >
        <Icon
          icon="ion:code-download-outline"
          color="#88F7FF"
          height="48"
          className={'mx-auto px-2 '}
        />
      </a>
    </div>
  )
}

export default Banner
