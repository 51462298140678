import { InlineIcon } from '@iconify/react'

export const ToolTip = ({ tipValue }) => {
  return (
    <span className="has-tooltip ">
      <span className="tooltip rounded border border-solid shadow-lg p-1 bg-gray-600 text-gray-200 -mt-8 capitalize">
        {tipValue}
      </span>
      <InlineIcon
        className="inline-block toolTip"
        height="10"
        icon="bx:bx-info-circle"
        hFlip={true}
      />
    </span>
  )
}

export const ExternalLink = ({ linkValue, textValue }) => {
  return (
    <a
      className={'font-semibold text-yellow-600'}
      href={linkValue}
      target={'_blank'}
      rel={'noreferrer'}
    >
      {' ' + textValue}
    </a>
  )
}
