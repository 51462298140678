import Banner from './Banner'
import About from './About'
import Skills from './Skills'
import Projects from './Projects'
import Contact from './Contact'

export default function Main() {
  return (
    <main className={'relative mx-auto w-full h-full'}>
      <Banner />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </main>
  )
}
