// import styled from 'styled-components'
// import vboColors from '../utils/style/vbo-colors'
import React from 'react'
// import { ThemeContext } from '../utils/context'
// import { Icon } from '@iconify/react'
import { Gitlab, LinkedIn, Twitter } from '../utils/assets'

// const FooterContainer = styled.footer`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
// `
//
// const NightModeButton = styled.button`
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
//   color: ${vboColors.vbo_gray_2};`

function Footer() {
  // const { toggleTheme, theme } = useContext(ThemeContext)
  return (
    <footer className={'w-full flex flex-row flex-wrap justify-center  mt-2 '}>
      <div className="w-full flex justify-center items-center">
        <a
          className=" py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
          href="https://www.linkedin.com/in/virginie-botiba-304725193/"
        >
          <img
            src={LinkedIn}
            className={'max-h-8 filter drop-shadow-lg'}
            alt={'icone linkedin'}
          />
        </a>
        <a
          className="flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
          href="https://twitter.com/VBotiba"
        >
          <img
            src={Twitter}
            className={'max-h-8 filter drop-shadow-lg'}
            alt={'icone twitter'}
          />
        </a>
        <a
          className="flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
          target={'_blank'}
          rel={'noreferrer'}
          href={'https://gitlab.com/oc-da-php-symfony-projects'}
        >
          <img
            src={Gitlab}
            className={'max-h-8 filter drop-shadow-lg'}
            alt={'icone gitlab'}
          />
        </a>
      </div>
      <div
        className={'w-full flex flex-wrap text-center text-sm justify-center'}
      >
        <div>
          <a
            href="/#contact"
            className="px-1.5 hover:text-vbo_blue_regular hover:bg-vbo_primary border-r-2 border-vbo_primary"
          >
            <span>Contact</span>
          </a>
        </div>
        <div>
          <a
            href="/#skills"
            className="px-1.5 hover:text-vbo_blue_regular hover:bg-vbo_primary border-r-2 border-vbo_primary"
          >
            <span>Compétences</span>
          </a>
        </div>
        <div>
          <a
            href="/#about"
            className="px-1.5 hover:text-vbo_blue_regular hover:bg-vbo_primary border-r-2 border-vbo_primary"
          >
            <span>Parcours</span>
          </a>
        </div>
        <div>
          <a
            href="/#projects"
            className="px-1.5 hover:text-vbo_blue_regular hover:bg-vbo_primary border-r-2 border-vbo_primary"
          >
            <span>Réalisation</span>
          </a>
        </div>
        <div>
          <a
            href="/"
            className="px-1.5 hover:text-vbo_blue_regular hover:bg-vbo_primary border-r-0"
          >
            <span>Home </span>
          </a>
        </div>
      </div>
      {/*<FooterContainer className={'w-full mt-2 pb-6 '}>*/}
      {/*  <NightModeButton onClick={() => toggleTheme()}>*/}
      {/*    <div className={'flex flex-row items-center justify-center '}>*/}
      {/*      {' '}*/}
      {/*      <span className={'mr-2 leading-3'}>Mode</span>{' '}*/}
      {/*      {theme === 'light' ? (*/}
      {/*        <Icon*/}
      {/*          icon="bx:bxs-moon"*/}
      {/*          rotate={1}*/}
      {/*          hFlip={true}*/}
      {/*          className={'inline-block text-yellow-600'}*/}
      {/*        />*/}
      {/*      ) : (*/}
      {/*        <Icon*/}
      {/*          icon="bx:bxs-sun"*/}
      {/*          className={'inline-block text-yellow-300'}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </NightModeButton>*/}
      {/*</FooterContainer>*/}
    </footer>
  )
}

export default Footer
