import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from '../utils/context'
import { StyledLink } from '../utils/style/Atoms'
import { Icon } from '@iconify/react'

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const { theme } = useContext(ThemeContext)
  const HeaderBG =
    theme === 'light'
      ? 'bg-vbo_body_background_light'
      : 'bg-vbo_body_background_dark'
  return (
    <nav
      className={
        'fixed ' +
        'w-full ' +
        'z-50 ' +
        'top-0 ' +
        'left-0 ' +
        'flex ' +
        'flex-wrap ' +
        'items-center ' +
        'justify-between ' +
        'bg-vbo_body_background_light '+
        'p-3 '
      }
    >
      <div
        className={
          'container mx-auto flex flex-wrap items-center justify-between ' +
          HeaderBG
        }
      >
        <div className="w-full relative flex flex-grow justify-between lg:w-auto lg:static text-vbo_blue_regular">
          <a className={'flex uppercase'} href="/">
            <div className="h-12 w-12 inline-block ">
              <div
                className={
                  'h-full w-full flex items-center justify-center rounded-full border-4 border-vbo_blue_regular  text-xl text-vbo_blue_regular '
                }
              >
                <span className={' text-2xl '}>{'<'}</span>
                <span
                  className={
                    'lowercase  font-semibold leading-snug font-bioRhyme'
                  }
                >
                  b
                </span>
              </div>
            </div>
            <div className="h-12 pl-1 flex items-center border-l-4 border-vbo_blue_regular ">
              <div>
                <span
                  className={
                    'block font-semibold text-lg tracking-tighter leading-tight'
                  }
                >
                  Virginie
                </span>
                <span
                  className={
                    'block tracking-wider leading-none font-extrabold text-xl'
                  }
                >
                  Botiba
                </span>
              </div>
            </div>
          </a>
          <button
            className="cursor-pointer text-3xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent justify-self-end lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div
          className={
            'lg:flex flex-grow items-center' +
            (navbarOpen ? ' flex' : ' hidden')
          }
          id="example-navbar-danger"
        >
          <ul className=" flex flex-col lg:flex-row list-none lg:ml-auto mt-4 lg:mt-auto">
            <li className="">
              <StyledLink
                href="/"
                className=" hover:text-yellow-600 hover:text-underline "
              >
                <span className={'lg:hidden'}>Home</span>
                <Icon
                  className={
                    'hidden text-vbo_blue_regular mx-auto lg:inline-block transition hover:text-yellow-800'
                  }
                  icon="bx:bxs-home"
                  hFlip={true}
                  height="32"
                />
              </StyledLink>
            </li>
            <li className="">
              <StyledLink
                href="/#about"
                className="inline-block hover:text-yellow-600 hover:text-underline"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span>Parcours</span>
              </StyledLink>
            </li>
            <li className="">
              <StyledLink
                href="/#skills"
                className="inline-block hover:text-yellow-600 hover:text-underline"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span>Compétences</span>
              </StyledLink>
            </li>
            <li className="">
              <StyledLink
                href="/#projects"
                className="inline-block hover:text-yellow-600 hover:text-underline"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span>Réalisations</span>
              </StyledLink>
            </li>

            <li className="mt-3 lg:mt-1.5 opacity-80">
              <a
                className={
                  'lg:flex lg:h-8 lg:w-24 lg:rounded-2xl lg:px-4 lg:items-center lg:justify-center lg:text-vbo_blue_regular lg:text-base lg:bg-transparent lg:border-vbo_blue_regular lg:border-4  transition hover:bg-yellow-400'
                }
                // $isFullLink
                href="/#contact"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span
                  className={'font-semibold mx-auto lg:filter drop-shadow-lg '}
                >
                  Contact
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
