export const Business =
  {
    id: 0,
    content: 'Business',
    color:  'text-gray-800',
    bgColor:  'bg-blue-100',
    borderColor:  ''
  }


export const Equipe =
  {
    id:  1,
    content:  "Equipe",
    color:  "text-gray-800",
    bgColor:  "bg-purple-300",
    borderColor:  ""
  }

export const Analyse =
  {
    id:  2,
    content:  "Analyse",
    color:  "text-gray-800",
    bgColor:  "bg-yellow-100",
    borderColor:  ""
  }

export const Architecture =
  {
    id: 3,
    content: "Architecture",
    color: "text-gray-800",
    bgColor: "bg-yellow-300",
    borderColor: ""
  }

export const Modelisation =
  {
    id: 4,
    content: "Modélisation",
    color: "text-gray-800",
    bgColor: "bg-yellow-500",
    borderColor: ""
  }

export const Ops =
  {
    id: 5,
    content: "DevOps",
    color: "text-vbo_blue_regular",
    bgColor: "bg-indigo-800",
    borderColor: ""
  }

export const Developpement =
  {
    id: 6,
    content: "Développement",
    color: "text-vbo_blue_regular",
    bgColor: "bg-indigo-500",
    borderColor: ""
  }

export const Accessibilite =
  {
    id: 7,
    content: "Accessibilité",
    color: "text-gray-800",
    bgColor: "bg-green-200",
    borderColor: "",
  }

export const Responsivite =
  {
    id: 8,
    content: "Responsivité",
    color: "text-gray-800",
    bgColor: "bg-green-100",
    borderColor: ""
  }

export const Securite =
  {
    id: 9,
    content: "Securité",
    color: "text-gray-800",
    bgColor: "bg-red-200",
    borderColor: ""
  }

export const Tests =
  {
    id: 10,
    content: "Tests",
    color: "text-vbo_blue_regular",
    bgColor: "bg-black",
    borderColor: ""
  }

export const Support =
  {
    id: 11,
    content: "Support",
    color: "text-gray-800",
    bgColor: "bg-indigo-200",
    borderColor: ""
  }

export const Conseil =
  {
    id: 12,
    content: "Conseil",
    color: "text-gray-800",
    bgColor: "bg-indigo-200",
    borderColor: ""
  }
export const Redactionnel =
  {
    id: 13,
    content: "Rédactionnel",
    color: "text-gray-800",
    bgColor: "bg-blue-300",
    borderColor: ""
  }

// export const Business = []
// Business.id = 0
// Business.content = 'Business'
// Business.color = 'text-gray-800'
// Business.bgColor = 'bg-blue-100'
// Business.borderColor = ''
//
// export const Equipe = []
// Equipe.id = 1
// Equipe.content = 'Equipe'
// Equipe.color = 'text-gray-800'
// Equipe.bgColor = 'bg-purple-300'
// Equipe.borderColor = ''
//
// export const Analyse = []
// Analyse.id = 2
// Analyse.content = 'Analyse'
// Analyse.color = 'text-gray-800'
// Analyse.bgColor = 'bg-yellow-100'
// Analyse.borderColor = ''
//
// export const Architecture = []
// Architecture.id = 3
// Architecture.content = 'Architecture'
// Architecture.color = 'text-gray-800'
// Architecture.bgColor = 'bg-yellow-300'
// Architecture.borderColor = ''
//
// export const Modelisation = []
// Modelisation.id = 4
// Modelisation.content = 'Modélisation'
// Modelisation.color = 'text-gray-800'
// Modelisation.bgColor = 'bg-yellow-500'
// Modelisation.borderColor = ''
//
// export const Ops = []
// Ops['id'] = 5
// Ops['content'] = 'DevOps'
// Ops['color'] = 'text-vbo_blue_regular'
// Ops['bgColor'] = 'bg-indigo-800'
// Ops['borderColor'] = ''
//
// export const Developpement = []
// Developpement.id = 6
// Developpement.content = 'Développement'
// Developpement.color = 'text-vbo_blue_regular'
// Developpement.bgColor = 'bg-indigo-500'
// Developpement.borderColor = ''
//
// export const Accessibilite = []
// Business.id = 7
// Accessibilite.content = 'Accessibilité'
// Accessibilite.color = 'text-gray-800'
// Accessibilite.bgColor = 'bg-green-200'
// Accessibilite.borderColor = ''
//
// export const Responsivite = []
// Responsivite.id = 8
// Responsivite.content = 'Responsivité'
// Responsivite.color = 'text-gray-800'
// Responsivite.bgColor = 'bg-green-100'
// Responsivite.borderColor = ''
//
// export const Securite = []
// Securite.id = 9
// Securite.content = 'Securité'
// Securite.color = 'text-gray-800'
// Securite.bgColor = 'bg-red-200'
// Securite.borderColor = ''
//
// export const Tests = []
// Tests.id = 10
// Tests.content = 'Tests'
// Tests.color = 'text-vbo_blue_regular'
// Tests.bgColor = 'bg-black'
// Tests.borderColor = ''
//
// export const Support = []
// Support.id = 11
// Support.content = 'Support'
// Support.color = 'text-gray-800'
// Support.bgColor = 'bg-indigo-200'
// Support.borderColor = ''
//
// export const Conseil = []
// Conseil.id = 12
// Conseil.content = 'Conseil'
// Conseil.color = 'text-gray-800'
// Conseil.bgColor = 'bg-indigo-200'
// Conseil.borderColor = ''
//
// export const Redactionnel = []
// Redactionnel.id = 13
// Redactionnel.content = 'Rédactionnel'
// Redactionnel.color = 'text-gray-800'
// Redactionnel.bgColor = 'bg-blue-300'
// Redactionnel.borderColor = ''
