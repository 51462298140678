import vboColors from './vbo-colors'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  padding: 10px;
  border: 6px solid ${vboColors.vbo_gray_1};
  border-bottom-color: transparent;
  border-radius: 22px;
  animation: ${rotate} 1s infinite linear;
  height: 0;
  width: 0;
`

export const StyledLink = styled.a`
  padding: 10px 15px 0 0;
  color: #384e70;
  text-decoration: none;
  //font-size: 18px;
  text-align: left;
  ${(props) =>
    props.$isFullLink &&
    `color: white; 
    text-align: center;
    border-radius: 30px; 
    background-color: ${vboColors.vbo_blue_regular};`}
`
