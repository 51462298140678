import React from 'react'
import GlobalStyle from './utils/style/GlobalStyle'
import { ThemeProvider } from './utils/context'
import NavBar from './components/Navbar'
import Main from './components/Main'
import Footer from './components/Footer'
import './styles/app.css'

export default function App() {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <NavBar />
      <Main />
      <Footer />
    </ThemeProvider>
  )
}
