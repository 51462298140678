import { useContext } from 'react'
import { createGlobalStyle } from 'styled-components'
import { ThemeContext } from '../context'
import vboColors from './vbo-colors'
import '../../styles/app.css'

function GlobalStyle() {
  const { theme } = useContext(ThemeContext)
  const StyledGlobalStyle = createGlobalStyle`

    body {
      height: 100%;
      overflow: auto;
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Space Mono', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${({ isDarkMode }) =>
        isDarkMode
          ? vboColors.vbo_body_background_dark
          : vboColors.vbo_body_background_light};
      color: var(--vbo-blue-dark)
      
    }
    
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    }

    .toolTip {
      vertical-align: 0.2em !important;
    }

    .TBorderColor {
      border-color: ${({ isDarkMode }) =>
        isDarkMode ? vboColors.vbo_blue_dark : vboColors.vbo_blue_regular};;
    }
  `

  return <StyledGlobalStyle isDarkMode={theme === 'dark'} />
}

export default GlobalStyle
