import {
  ajax,
  apache,
  atlassian,
  behat,
  bootstrap,
  composer,
  docker,
  git,
  html5,
  javascript,
  jquery,
  linux,
  mariadb,
  materializecss,
  mysql,
  npm,
  php,
  phpunit,
  reactjs,
  sass,
  symfony,
  tailwindcss,
  webpack,
  yarn,
} from '../utils/assets'
import {
  Accessibilite,
  Analyse,
  Architecture,
  Business,
  Conseil,
  Developpement,
  Equipe,
  Modelisation,
  Ops,
  Redactionnel,
  Responsivite,
  Securite,
  Support,
  Tests,
} from './Tags'

export const skills = [
  {
    id: 0,
    title: 'Recueillir les besoins client/utilisateur',
    tags: [Analyse],
    level: '',
  },
  {
    id: 1,
    title:
      'Réaliser l’analyse fonctionnelle d’un projet, en établir le cahier des charges',
    tags: [Analyse, Redactionnel],
    level: '',
  },
  {
    id: 2,
    title:
      'Définir l’architecture d’ une application, en modéliser le modèle de données et les classes',
    tags: [Architecture, Modelisation],
    level: '',
  },
  {
    id: 3,
    title: 'Rédiger une proposition technique et commerciale',
    tags: [Analyse, Redactionnel, Business],
    level: '',
  },
  {
    id: 4,
    title:
      'Développer une application Web en suivant les exigences du cahier des charges',
    tags: [Developpement],
    level: '',
  },
  {
    id: 5,
    title: 'Développer une application en conformité avec les standards',
    tags: [Developpement, Securite, Accessibilite, Responsivite],
    level: '',
  },
  {
    id: 6,
    title: 'Développer une API accédant aux niveaux de maturité de Richardson',
    tags: [Developpement],
    level: '',
  },
  {
    id: 7,
    title: 'Rédiger une documentation technique',
    tags: [Support, Redactionnel],
    level: '',
  },
  {
    id: 8,
    title:
      'Ecrire des jeux de tests en amont ou en aval du développement d’une application',
    tags: [Tests],
    level: '',
  },
  {
    id: 9,
    title:
      'Effectuer un audit fonctionnel/performance et en rédiger le rapport',
    tags: [Redactionnel, Conseil],
    level: '',
  },
  {
    id: 10,
    title: 'Débogger une application',
    tags: [Developpement, Support],
    level: '',
  },
  {
    id: 11,
    title: 'Mettre en place un pipeline d’Intégration Continue',
    tags: [Ops],
    level: '',
  },
  {
    id: 12,
    title: 'Configurer un serveur Apache',
    tags: [Ops],
    level: '',
  },
  {
    id: 13,
    title: 'Utiliser un outil de versionning',
    tags: [Developpement, Ops],
    level: '',
  },
  {
    id: 14,
    title: 'Adopter une méthodologie Agile',
    tags: [Equipe],
    level: '',
  },
]

export const Tech = [
  {
    id: 0,
    title: 'Linux',
    badge: linux,
  },
  {
    id: 1,
    title: 'Apache',
    badge: apache,
  },
  {
    id: 2,
    title: 'Docker',
    badge: docker,
  },
  {
    id: 3,
    title: 'PHP',
    badge: php,
  },
  {
    id: 4,
    title: 'MySql',
    badge: mysql,
  },
  {
    id: 5,
    title: 'MariaDb',
    badge: mariadb,
  },
  {
    id: 6,
    title: 'Symfony',
    badge: symfony,
  },
  {
    id: 7,
    title: 'Javascript',
    badge: javascript,
  },
  {
    id: 8,
    title: 'ReactJs',
    badge: reactjs,
  },
  {
    id: 9,
    title: 'JQuery',
    badge: jquery,
  },
  {
    id: 10,
    title: 'Git',
    badge: git,
  },
  {
    id: 11,
    title: 'Symfony',
    badge: symfony,
  },
  {
    id: 12,
    title: 'Ajax',
    badge: ajax,
  },
  {
    id: 13,
    title: 'Composer',
    badge: composer,
  },
  {
    id: 14,
    title: 'Npm',
    badge: npm,
  },
  {
    id: 15,
    title: 'Yarn',
    badge: yarn,
  },
  {
    id: 16,
    title: 'Html5',
    badge: html5,
  },
  {
    id: 17,
    title: 'Webpack',
    badge: webpack,
  },
  {
    id: 18,
    title: 'Sass',
    badge: sass,
  },
  {
    id: 19,
    title: 'Bootstrap',
    badge: bootstrap,
  },
  {
    id: 20,
    title: 'TailwindCss',
    badge: tailwindcss,
  },
  {
    id: 21,
    title: 'MaterializeCss',
    badge: materializecss,
  },
  {
    id: 22,
    title: 'Atlassian',
    badge: atlassian,
  },
  {
    id: 23,
    title: 'Behat',
    badge: behat,
  },
  {
    id: 24,
    title: 'PhpUnit',
    badge: phpunit,
  },
]
