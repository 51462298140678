import { projects } from '../data/Projects'
import React from 'react'
import * as PropTypes from 'prop-types'
import Slider from 'react-slick'
import '../styles/slick-carousel.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

Slider.propTypes = {
  slidesToScroll: PropTypes.number,
  dots: PropTypes.bool,
  speed: PropTypes.number,
  infinite: PropTypes.bool,
  slidesToShow: PropTypes.number,
  children: PropTypes.node,
}
function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        paddingLeft: 20,
      }}
      onClick={onClick}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        marginLeft: -20,
      }}
      onClick={onClick}
    />
  )
}
export default function ProjectsSlider() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          swipeToSlide: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          swipeToSlide: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    className: 'w-11/12 mx-auto p-6',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <Slider {...settings}>
      {projects.map((project) => (
        <div className={'text-yellow-600 px-2 w-full h-full'} key={project.id
        }>
          <a
            href={project.link}
            key={project.image}
            className="hover:opacity-75 "
            target={'_blank'}
            rel={'noreferrer'}
          >
            <h2 className=" tracking-tighter text-center text-xs md:text-sm lg:text-lg lg:tracking-widest font-medium uppercase mb-2 ">
              {project.title}
            </h2>
            <img
              alt="project illustration"
              className={''}
              src={project.image}
            />
            <h3 className="text-center text-xs md:text-sm font-medium uppercase mt-2 ">
              {project.subtitle}
            </h3>
            <p className="text-center text-xs md:text-sm">
              {project.description}
            </p>
          </a>
        </div>
      ))}
    </Slider>
  )
}
