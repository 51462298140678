import { Icon } from '@iconify/react'
import { AboutDoodle } from '../utils/assets'
import { ExternalLink } from './Extras'

function About() {
  return (
    <section id={'about'} className={'pt-10 md:py-4'}>
      <div className={'h-full flex items-center justify-center '}>
        <div className="bg-vbo_blue_dark text-white ">
          <div className="sticky flex flex-row items-center justify-center bg-vbo_blue_dark  z-10 mt-8 md:-mt-4 md:top-16 md:py-6 ">
            <h1
              className={
                'font-bioRhyme flex items-center px-4 text-center  text-yellow-600 text-xl lg:text-4xl leading-normal uppercase tracking-loose'
              }
            >
              <Icon
                icon="grommet-icons:resume"
                className={'w-10 h-10 hidden md:inline-block '}
              />
              Il était une fois..
            </h1>
          </div>
          <div className="container mx-auto flex flex-col items-start lg:flex-row my-12 md:my-18 break-words">
            <div className="flex flex-col w-full sticky lg:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
              <div className="bg-black bg-opacity-30 p-4 pt-6">
                <h2 className="text-xl mb-2">Bienvenue</h2>
                <p className="text-xs tracking-tight mb-4 text-justify leading-tight ">
                  Ce site est réalisé avec ReactJs (CRA) et TailwindCss. Il est
                  100% statique, mais pourrait consommer des données avec une
                  API, réalisée par exemple sous Symfony avec API Platform ou
                  encore Express.
                </p>
                <p className="text-xs tracking-tight mb-4 text-justify leading-tight">
                  Titulaire d'un Bachelor en développement d'Application Php
                  symfony, après 2 années d'expérience de Dev en entreprise et
                  beaucoup d'auto-formation dans la foulée, j'enchaine
                  l'acquisition de compétences, avec un MBA en Marketing et
                  Communication.
                </p>
                <p className="text-xs tracking-tight mb-4 text-justify leading-tight">
                  Opérationnelle et force de proposition au sein de mon équipe
                  ou face à mon client, je vous souhaite une agréable visite à
                  la découverte de mon parcours, en espérant échanger avec vous
                  bientôt.
                </p>
                <a
                  href="#contact"
                  className="
                  bg-transparent
                  flex
                  justify-center
                  items-center
                  self-center
                  hover:bg-yellow-600 text-yellow-600 hover:text-white rounded shadow hover:shadow-lg  border border-yellow-600 hover:border-transparent"
                >
                  Contact
                </a>
              </div>
            </div>
            <div className="border-solid border-b-4 border-yellow-600 ml-0 md:ml-12 lg:mt-12 lg:w-2/3 sticky">
              <div className="container mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden py-20 h-full">
                  <p className="text-yellow-600 uppercase text-center mb-4 ">
                    Parcours
                  </p>
                  <div
                    className="border-2-2 border-yellow-600 absolute h-full border"
                    style={{
                      right: '50%',
                      borderWidth: ' 2px',
                      borderStyle: 'solid',
                      borderRadius: ' 1%',
                    }}
                  />
                  <div
                    className="border-2-2 border-yellow-600 absolute h-full border"
                    style={{
                      left: '50%',
                      borderWidth: '2px',
                      borderRadius: ' 1%',
                    }}
                  />
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-2 text-left">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Mars 2024 - en cours
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Chef de Produit
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://vickabanana.com/'}
                          textValue={
                            'Vicka Banana - E-Commerce, Print On Demand et Affiliation'
                          }
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Définition des objectifs et des prix, Création de
                        tableaux de bord, Programme d'affiliation
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Création et configuration de la boutique Shopify,
                        sélection et connexion des fournisseurs POD.
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Création de designs et d'articles, Gestion du site
                        e-commerce et des réseaux sociaux. Google Merchant
                        Center
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-2 text-left">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Janv. 2024 - Mars 2024
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Stagiaire Marketing
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={
                            'https://www.google.com/search?rlz=1C1CHZO_frFR927FR928&q=Acart%20-%20Atelier%20Couture%20D%C3%A9pot%20Vente%20-%20Mondial%20Relay%20Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2sTSyNDEyMzU3sTQ3NzQ1MzI23sDI-IrR0jE5sahEQVfBsSQ1JzO1SME5v7SktChVweXwyoL8EoWw1LySVKC0b35eSmZijkJQak5ipYJjWWbxIlby9QIAxgdjeZIAAAA&rldimm=4929426574977156233&tbm=lcl&hl=fr&sa=X&ved=0CBoQ9fQKKABqFwoTCJjrmcSVhoYDFQAAAAAdAAAAABAG&biw=1334&bih=628&dpr=1.44#lkt=LocalPoiReviews'
                          }
                          textValue={
                            "A.C.A.R.T - Association Chantiers d'Insertion"
                          }
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Réalisation de documents de procédures internes et
                        Conception d’une application Excel/VBA : Tableau de
                        bord, base de donnée et formulaires pour la saisie de
                        données papier et mise en place d’indicateurs.
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-2 text-right">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Nov. 2022 - en Cours
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        MBA - Manager Produit et Marketing
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.studi.com/fr/'}
                          textValue={'Studi'}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Titre RNCP N7
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-2 text-left">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Novembre 2021
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Développeuse Fullstack
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://redboots.io/'}
                          textValue={'RedBoots - (Activité en création)'}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Avec redboots.io, c'est le début d'une aventure
                        indépendante accompagnée par la couveuse Altitude.
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-2 text-right">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Sept. 2019 - Sept. 2021
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Bachelor - Développeuse d'Applications PHP-Symfony
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.openclassrooms.com/'}
                          textValue={'OpenClassrooms'}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Titre RNCP N6
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Sept. 2019 - Juil. 2021
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Développeuse d'Applications PHP-Symfony
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.net15.fr'}
                          textValue={'Net 15'}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        CDD - Alternance
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Juil. 2018 - Sept. 2019
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Technicienne SAV, développeuse
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.net15.fr/'}
                          textValue={'Net 15 '}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        CDD - Temps partiel
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        Oct. 2010 - Fév. 2011
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Développeuse PHP
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.studyrama.com/'}
                          textValue={'Studyrama '}
                        />
                        <span>Via</span>
                        <ExternalLink
                          linkValue={'https://www.expectra.fr/'}
                          textValue={'Expectra '}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Interim
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-4  text-right">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        2005 - 2006
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Développeuse de Sites Web Dynamiques
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.ifocop.fr/'}
                          textValue={'Ifocop'}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Niveau 5
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4">
                      <p className="mb-1 text-sm md:text-base text-yellow-600">
                        1997 - 2018
                      </p>
                      <h4 className="mb-2 font-bold  text-sm md:text-xl text-left tracking-tighter">
                        Auteur compositeur Interprète, Choriste accompagnatrice
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.ananzebatanga.com/'}
                          textValue={'Ananze Batanga'}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Artiste Indépendante
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-4 text-right">
                      <p className="mb-1 md:text-base text-yellow-600">
                        1994 - 1996
                      </p>
                      <h4 className="mb-2 font-bold text-sm md:text-xl tracking-tighter">
                        Musique & Chant Jazz
                      </h4>
                      <p className={'text-base'}>
                        <ExternalLink
                          linkValue={'https://www.lecim.com/'}
                          textValue={'CIM'}
                        />
                        <span className={'text-base text-yellow-600'}> & </span>
                        <ExternalLink
                          linkValue={
                            'https://conservatoires.paris.fr/conservatoires/boulanger/'
                          }
                          textValue={'Conservatoire Nadia et Lili Boulanger '}
                        />
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        Niveau 5
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  className="mx-auto w-96  -mt-72 sm:-mt-80 lg:-mt-96 "
                  src={AboutDoodle}
                  alt={'deco'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
