import VBOLogo from '../assets/img/logo.png'
import VBOGitlab from '../assets/img/bx_bxl-gitlab.png'
import VBOLinkedIn from '../assets/img/bx_bxl-linkedin-square.png'
import VBOTwitter from '../assets/img/bx_bxl-twitter.png'
import VBOMalbert from '../assets/img/malbert.png'
import VBOComarquage from '../assets/img/lamothe43_service_public.png'
import VBOMyBlog from '../assets/img/myblog.png'
import VBOSnowtricks from '../assets/img/snowtricks.png'
import VBOBilmo from '../assets/img/bilmo.png'
import VBONet15Reglement from '../assets/img/reglement_net15.png'
import VBOVickaBanana from '../assets/img/vickabanana.png'
import VBOAbout from '../assets/img/about.png'
import VBOgLandscape from '../assets/img/og-landscape.png'
import { Icon } from '@iconify/react'

export const Logo = VBOLogo
export const Gitlab = VBOGitlab
export const LinkedIn = VBOLinkedIn
export const Twitter = VBOTwitter
export const Malbert = VBOMalbert
export const Comarquage = VBOComarquage
export const MyBlog = VBOMyBlog
export const Snowtricks = VBOSnowtricks
export const Bilmo = VBOBilmo
export const Net15Reglement = VBONet15Reglement
export const VickaBanana = VBOVickaBanana
export const AboutDoodle = VBOAbout
export const OgLandscape = VBOgLandscape

export const linux = <Icon icon="logos:linux-tux" />
export const apache = <Icon icon="logos:apache" />
export const docker = <Icon icon="vscode-icons:file-type-docker" />
export const php = <Icon icon="file-icons:php" />
export const mysql = <Icon icon="grommet-icons:mysql" />
export const mariadb = <Icon icon="vscode-icons:file-type-mariadb" />
export const symfony = <Icon icon="fa-brands:symfony" />
export const javascript = <Icon icon="tabler:brand-javascript" />
export const reactjs = <Icon icon="vscode-icons:file-type-reactjs" />
export const jquery = <Icon icon="cib:jquery" />
export const git = <Icon icon="fa-brands:git-square" />
export const ajax = <Icon icon="whh:ajax" />
export const composer = <Icon icon="logos:composer" />
export const npm = <Icon icon="cib:npm" />
export const yarn = <Icon icon="logos:yarn" />
export const html5 = <Icon icon="akar-icons:html-fill" />
export const webpack = <Icon icon="logos:webpack" />
export const sass = <Icon icon="logos:sass" />
export const bootstrap = <Icon icon="fa-brands:bootstrap" />
export const tailwindcss = <Icon icon="logos:tailwindcss-icon" />
export const materializecss = <Icon icon="logos:materializecss" />
export const atlassian = <Icon icon="logos:atlassian" />
export const behat = <Icon icon="file-icons:behat" />
export const phpunit = <Icon icon="file-icons:phpunit" />
