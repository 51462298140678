import { BadgeCheckIcon, ChipIcon } from '@heroicons/react/outline'
import { skills, Tech } from '../data/Skills'

export default function Skills() {
  return (
    <section id={'skills'} className={'pt-4 md:py-2 '}>
      <div className={'h-full flex items-center justify-center'}>
        <div className="text-vbo_blue_dark ">
          <div className="flex flex-row items-center justify-center bg-vbo_blue_regular z-10 mt-20 md:-mt-4 lg:mt-8 pt-0 sticky md:top-16 md:py-4 ">
            <h1
              className={
                'font-bioRhyme font-bold flex items-center px-4 text-center text-base md:text-2xl lg:text-4xl leading-normal uppercase tracking-tight md:tracking-loose'
              }
            >
              <ChipIcon className={'w-10 h-10 hidden md:inline-block '} />
              Ce que je peux faire pour votre projet
            </h1>
          </div>
          <div className="container h-full mx-auto flex flex-col items-start lg:pt-6 md:flex-row my-12 md:my-24 lg:my-12">
            <div className={'lg:w-2/3 sm:flex justify-start -mt-12 md:mt-8'}>
              <div
                className={
                  'sm:h-12 sm:w-72 sm:-ml-40 bg-vbo_blue_regular transform sm:origin-top-right sm:-rotate-90 sm:-translate-x-12 '
                }
              >
                <p
                  className={
                    'text-vbo_blue_dark text-lg md:text-2xl lg:text-3xl uppercase tracking-wider sm:ml-6 mt-1 text-center '
                  }
                >
                  Compétences
                </p>
              </div>
              <div
                className="container lg:flex flex-wrap items-start sm:mx-auto sm:mb-2
               border-vbo_blue_regular border-solid lg:border-l-4 "
              >
                {skills.map((skill) => (
                  <div key={skill.id} className="lg:w-1/2 h-42 my-4 p-2  ">
                    <div
                      className={
                        'bg-white h-36 w-11/12 bg-opacity-80 flex  flex-grow flex-col mx-auto py-1 border-2 border-vbo_blue_regular filter drop-shadow-md'
                      }
                    >
                      <div className="flex flex-row   h-full pl-2 items-start ">
                        <BadgeCheckIcon className=" w-1/12 text-vbo_blue_regular font-bold filter drop-shadow-sm mr-1 " />
                        <span className="text-sm break-words font-medium text-vbo_blue_dark w-11/12">
                          {skill.title}
                        </span>
                      </div>
                      <div className="flex flex-wrap">
                        {skill.tags.map((tag, idx )=> (
                          <span
                            key={skill.name + idx + tag.content}
                            className={
                              'flex flex-grow-0 text-xs m-1 p-1 border-1 tracking-tighter rounded bg-opacity-90 font-bold filter drop-shadow ' +
                              tag.bgColor +
                              ' ' +
                              tag.color
                            }
                          >
                            {tag.content}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={'lg:w-1/3 sm:flex items-start sm:ml-20 md:mt-8'}>
              <div
                className={
                  'sm:h-12 sm:w-36 sm:-ml-40 bg-vbo_blue_regular transform sm:origin-top-right sm:-rotate-90 sm:-translate-x-10 '
                }
              >
                <p
                  className={
                    'text-vbo_blue_dark text-lg md:text-2xl lg:text-3xl uppercase tracking-wider sm:ml-14 mt-1 text-center'
                  }
                >
                  Tech
                </p>
              </div>
              <div
                className={
                  'container flex flex-wrap items-start justify-center md:justify-start sm:mx-auto sm:mb-2 border-vbo_blue_regular border-solid lg:border-l-4 '
                }
              >
                {Tech.map((tech) => (
                  <div
                    key={tech.id}
                    className="m-1 p-3 flex flex-col justify-center items-center filter drop-shadow-lg"
                  >
                    <span className="text-vbo_blue_dark w-20 h-20 rounded-full flex items-center justify-center text-3xl border-vbo_blue_regular border-8 filter bg-vbo_body_background_light drop-shadow-lg">
                      {tech.badge}
                    </span>
                    <p className="w-20 text-center text-sm text-vbo_blue_dark mt-1 overflow-ellipsis overflow-hidden ">
                      {tech.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
