const vboColors = {
  vbo_primary: '#626060',
  vbo_blue_dark: '#384E70',
  vbo_blue_regular: 'rgb(136, 247, 255)',
  vbo_blue_regular_light: 'rgba(136, 247, 255, .25)',
  vbo_blue_extra_light: '#D2FFFF',
  vbo_gray_1: '#4B5563',
  vbo_gray_2: '#6C86AA',
  vbo_body_background_light: '#D2FFFF',
  vbo_body_background_dark: '#0E131F',
}

export default vboColors
