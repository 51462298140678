import {
  Bilmo,
  Comarquage,
  Malbert,
  MyBlog,
  Net15Reglement,
  Snowtricks,
  VickaBanana,
} from '../utils/assets'

export const projects = [
  {
    id: 6,
    title: 'Vicka Banana',
    subtitle: 'E-Commerce, Print On Demand et Affiliation',
    tech: 'Shopify, Notion',
    description:
      "Création d'une boutique en ligne synchronisée aux fournisseurs POD et de bases de données Notioin pour la gestion des articles et des partenariats. ",
    image: VickaBanana,
    link: 'https://vickabanana.com',
  },
  {
    id: 0,
    title: 'Malbert',
    subtitle: 'Projet Net15',
    tech: 'Application Symfony 4 et Php7',
    description: 'Boutique en ligne de l\'entreprise "Malbert & Fils"',
    image: Malbert,
    link: 'https://malbert.fr',
  },
  {
    id: 1,
    title: 'Comarquage Service Public',
    subtitle: 'Projet Net15',
    tech: 'Application Symfony 5 XSL Php 8',
    description:
      'Récupération des informations du Service Public pour intégration dans le CMS Websee',
    image: Comarquage,
    link: 'https://www.lamothe43.fr/service-public-fr_fr.html',
  },
  {
    id: 2,
    title: 'Règlement Net15',
    subtitle: 'Projet Net15',
    tech: 'Application Symfony 4 Php7',
    description:
      "Interface de paiement en ligne de l'entreprise Ne15 pour les clients non mensualisés",
    image: Net15Reglement,
    link: 'https://reglement.net15.fr',
  },
  {
    id: 3,
    title: 'MyBlog',
    subtitle: "Projet d'études",
    tech: 'Application Php7 Natif',
    description:
      'Blog personnel avec administration sécurisée pour la gestion des articles et des utilisateurs',
    image: MyBlog,
    link: 'https://myblog.ananzebatanga.com',
  },
  {
    id: 4,
    title: 'Snowtricks',
    subtitle: "Projet d'études",
    tech: 'Application Symfony 4 Php7',
    description:
      'Plateforme Participative de figures de snowboard avec gestion de comptes utilisateurs et galeries multimédia',
    image: Snowtricks,
    link: 'https://snowtricks.ananzebatanga.com',
  },
  {
    id: 5,
    title: 'Bilmo API',
    subtitle: "Projet d'études: ",
    tech: 'API REST Symfony 5 Php7',
    description:
      "Création d'une API Rest exposant le modèle de maturité de richardson et ses 4 niveaux. ",
    image: Bilmo,
    link: 'https://bilmo.ananzebatanga.com',
  },
]
