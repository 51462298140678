import ContactForm from './ContactForm'
export default function Contact() {
  return (
    <section id={'contact'} className="body-font relative">
      <div className="container px-5 py-20 mx-auto">
        <div className="flex flex-col text-center w-full mb-8">
          <h1 className="sm:text-3xl text-2xl font-medium uppercase mb-4">
            Contact
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base" />
        </div>
        <ContactForm />
      </div>
    </section>
  )
}
